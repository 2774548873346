import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import App from 'App'
import { space, colors, COLOR_CONSTANTS } from 'theme'
import { Box, Flex } from 'components/Layout'
import { H1, H3, Text } from 'components/Typography'
import Container from 'components/Container'
import LocalImage from 'components/LocalImage'
import bgImage from 'static/images/bg_page_header.svg'
import facebookIcon from 'static/svg/facebook_icon.svg'
import linkedinIcon from 'static/svg/linkedin_icon.svg'
import twitterIcon from 'static/svg/twitter_icon.svg'
import instagramIcon from 'static/svg/instagram_icon.svg'
import youtubeIcon from 'static/svg/youtube_icon.svg'

const StyledText = styled(Text)`
  opacity: 0.8;
`

const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${colors.primary};
`

const StyledImg = styled.img`
  width: 35px;
`

const SocialNetworkLink = styled.a`
  padding-left: ${space.m};
  padding-right: ${space.m};
  color: initial;
  text-decoration: none;
  &:visited {
    color: initial;
  }
`

const StyledH3 = styled(H3)`
  opacity: 0.8;
`

const BgImg = styled(LocalImage)`
  top: ${({ top }) => top || 0};
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  position: absolute;
  background-repeat: no-repeat;
  background-position: center;
  object-fit: cover;
  z-index: -1;
  left: 50%;
  transform: translate(-50%);
  height: ${({ height }) => height || 'auto'};
`

const Wrapper = styled(Flex)`
  position: relative;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
`

const Received = () => {
  return (
    <App>
      <Wrapper mt={{ mobile: 0, desktop: `-${space.xl}` }}>
        <Container pl="0" pr="0" position="relative" maxWidth="1800px" width="100%" height="100%">
          <BgImg src={bgImage} width={{ mobile: '100%', mobileLarge: 'auto' }} maxWidth="1440px" />
          <Flex mt="xxl" px="m" flexDirection="column" alignItems="center">
            <H1 textAlign="center" color={COLOR_CONSTANTS.DENIM}>
              Thank you!
            </H1>
            <Box mt="m" maxWidth="600px" textAlign="center">
              <StyledH3 color="secondaryText">We have received your request will respond as soon as possible!</StyledH3>
            </Box>
            <StyledText mt="m" fontSize="l" color="secondaryText" textAlign="center">
              Learn more about Vista Social
            </StyledText>
            <Flex my="l" flexWrap="wrap">
              <SocialNetworkLink href="https://twitter.com/vistasocialapp">
                <StyledImg src={twitterIcon} />
              </SocialNetworkLink>
              <SocialNetworkLink href="https://www.linkedin.com/company/vistasocialapp/">
                <StyledImg src={linkedinIcon} />
              </SocialNetworkLink>
              <SocialNetworkLink href="https://instagram.com/vistasocialapp">
                <StyledImg src={instagramIcon} />
              </SocialNetworkLink>
              <SocialNetworkLink href="https://www.facebook.com/vistasocialapp">
                <StyledImg src={facebookIcon} />
              </SocialNetworkLink>
              <SocialNetworkLink href="https://youtube.com/@vistasocialapp">
                <StyledImg src={youtubeIcon} />
              </SocialNetworkLink>
            </Flex>
            <StyledLink to="/">Back to homepage</StyledLink>
          </Flex>
        </Container>
      </Wrapper>
    </App>
  )
}

export default Received
